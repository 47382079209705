import { startStimulusApp } from '@symfony/stimulus-bridge';
export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!../vue/views',
    true,
    /\.(j|t)sx?$/
));


import { registerVueControllerComponents } from '@symfony/ux-vue';
registerVueControllerComponents(require.context('../vue/views', true, /\.vue$/, 'lazy'));

// NEEDED IF YOU USE PINIA

import {createPinia, getActivePinia} from 'pinia';
document.addEventListener('vue:before-mount', (event) => {
    const {
        app,
    } = event.detail;

    const pinia = getActivePinia() || createPinia();

    app.use(pinia);
});

import Alert from 'bootstrap/js/dist/alert';
import Dropdown from 'bootstrap/js/dist/dropdown';

onFind('.alert',function (element) {
    new Alert(element)
});

onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element)
});


import {onFind} from '@elements/init-modules-in-scope';

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as lightbox from '@elements/lightbox';

lightbox.init({
    plugins: ['zoom', 'video'],
    zoom: true,
    video: true,
    mobileSettings: {
        controls: true
    },
    showConsent: !Cookiebot.consented
});

import * as spriteIcons from './sprite-icons';

import Collapse from 'bootstrap/js/dist/collapse';

import * as affixNav from './affix-nav';
affixNav.init();

import * as nav from './nav';
nav.init();

import * as navActiveState from "./nav-active-state.js";
navActiveState.init();

import * as formControlSubmit from './form-control-submit';
formControlSubmit.init();

import * as hashCash from './hashCash';

import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init();

import * as parallax from './parallax';
parallax.init();

import * as showMore from './show-more';
showMore.init();

import * as customSelect from "./custom-select.js";
customSelect.init();

import * as asyncAppendViewport from "./async-append-viewport";
asyncAppendViewport.init();

import * as infoNotification from './info-notification';
infoNotification.init();

import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as consentOverlay from '@elements/consent-overlay';

consentOverlay.init({
        cmpTool: 'cookiebot',
        revalidateEvent: 'CookiebotOnConsentReady'
    },
    {
        base: '.js-consent',
        template: '.js-consent__template',
        dataset: 'consent',
        button: '.js-consent__accept-btn'
    });

import * as tracking from "@elements/tracking";
let options = {}; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import Modal from 'bootstrap/js/dist/modal';

// Datepicker hotfix for modal
import { on, closest } from "@elements/dom-utils";
on('focusin', (e) => {
    if(closest('.flatpickr-calendar', e.target) !== null) {
        e.stopImmediatePropagation();
    }
}, document);

//smartbanner
import SmartBanner from 'smartbanner.js/dist/smartbanner.js';