import {onFind} from "@elements/init-modules-in-scope";
import {addClass, removeClass, findIn, on, find} from '@elements/dom-utils';
export function init () {
    window.addEventListener('load', () => {
        onFind('.js-info-notification', (alertNotification) => {
            let close = findIn('.js-info-notification__close', alertNotification);
            let backdrop = find('.js-info-notification__backdrop');
            let id = alertNotification.getAttribute('data-info-notification-id');

            on('click', (e) => {
                removeClass('is-active', alertNotification);
                removeClass('is-active', backdrop);
                sessionStorage.setItem('infoNotification-' + id, 'closed');
            }, close);

            if(sessionStorage.getItem('infoNotification-' + id) === 'closed') {
                removeClass('is-active', alertNotification);
                removeClass('is-active', backdrop);
            }else{
                addClass('is-active', alertNotification);
                addClass('is-active', backdrop);
            }

        });
    });
}